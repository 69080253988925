define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-site-setting-notice", ["exports", "@glimmer/component", "@ember/service", "@ember/string", "discourse-common/helpers/d-icon", "discourse-common/lib/get-url", "I18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _string, _dIcon, _getUrl, _I18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ActivityPubSiteSettingNotice;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ActivityPubSiteSettingNotice = _exports.default = (_class = (_ActivityPubSiteSettingNotice = class ActivityPubSiteSettingNotice extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get containerClass() {
      return `activity-pub-site-setting ${(0, _string.dasherize)(this.args.setting)}`;
    }
    get label() {
      return _I18n.default.t(`category.discourse_activity_pub.site_setting.${this.args.setting}.label`);
    }
    get title() {
      return _I18n.default.t(`category.discourse_activity_pub.site_setting.${this.args.setting}.title`);
    }
    get description() {
      return _I18n.default.t(`category.discourse_activity_pub.site_setting.${this.args.setting}.description`);
    }
    get url() {
      return (0, _getUrl.default)(`/admin/site_settings/category/all_results?filter=${this.args.setting}`);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class={{this.containerClass}} title={{this.title}}>
        <a class="activity-pub-site-setting-label" href={{this.url}}>
          {{icon "cog"}}
          {{this.label}}
        </a>
        <span>{{this.description}}</span>
      </div>
    
  */
  {
    "id": "Ez5RQC9T",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"containerClass\"]]],[15,\"title\",[30,0,[\"title\"]]],[12],[1,\"\\n      \"],[10,3],[14,0,\"activity-pub-site-setting-label\"],[15,6,[30,0,[\"url\"]]],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"cog\"],null]],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"description\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-site-setting-notice.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), _ActivityPubSiteSettingNotice), _ActivityPubSiteSettingNotice), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});