define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-nav", ["exports", "@glimmer/component", "@ember/service", "discourse/components/nav-item", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _navItem, _activityPubFollowBtn, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ActivityPubCategoryNav;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ActivityPubCategoryNav = _exports.default = (_class = (_ActivityPubCategoryNav = class ActivityPubCategoryNav extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
    }
    get showFollows() {
      return this.args.category.can_edit;
    }
    get showFollowers() {
      return this.site.activity_pub_publishing_enabled;
    }
    get onFollowsRoute() {
      return this.router.currentRouteName === "activityPub.category.follows";
    }
    get showCreateFollow() {
      return this.showFollows && this.onFollowsRoute;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-category-nav">
        <ul class="nav nav-pills">
          {{#if this.showFollowers}}
            <NavItem
              @route="activityPub.category.followers"
              @label="discourse_activity_pub.category_nav.followers"
            />
          {{/if}}
          {{#if this.showFollows}}
            <NavItem
              @route="activityPub.category.follows"
              @label="discourse_activity_pub.category_nav.follows"
            />
          {{/if}}
        </ul>
        {{#if this.showCreateFollow}}
          <ActivityPubFollowBtn
            @actor={{@category.activity_pub_actor}}
            @follow={{@follow}}
            @type="actor_follow"
          />
        {{/if}}
      </div>
    
  */
  {
    "id": "3abUAxyn",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-category-nav\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n\"],[41,[30,0,[\"showFollowers\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"activityPub.category.followers\",\"discourse_activity_pub.category_nav.followers\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showFollows\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"activityPub.category.follows\",\"discourse_activity_pub.category_nav.follows\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"],[41,[30,0,[\"showCreateFollow\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@actor\",\"@follow\",\"@type\"],[[30,1,[\"activity_pub_actor\"]],[30,2],\"actor_follow\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@category\",\"@follow\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-nav.js",
    "scope": () => [_navItem.default, _activityPubFollowBtn.default],
    "isStrictMode": true
  }), _ActivityPubCategoryNav), _ActivityPubCategoryNav), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});